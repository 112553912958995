@import 'variables';

html {
    font-size: 16px;
}

// *** Add bootstrap overrides above ***

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@import 'ngx-toastr/toastr-bs5-alert';

// ***************************************

// Add global styles below (specific styles/component styles should be added in each component *.scss)

//toastr overrides

.toast-base {
  border-radius: 8px;
  color: $black !important;
  border-width: 1px;
  border-style: solid;
}

.toast-success {
  @extend .toast-base;
  background-color: $state-success;
  border-color: darken($state-success, $state-darken);
}
.toast-error {
  @extend .toast-base;
  background-color: $state-error;
  border-color: darken($state-error, $state-darken);
}
.toast-info {
  @extend .toast-base;
  background-color: $state-info;
  border-color: darken($state-info, $state-darken);
}
.toast-warning {
  @extend .toast-base;
  background-color: $state-warning;
  border-color: darken($state-warning, $state-darken);
}

.info-panel {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  background-color: $state-info;
  border-color: darken($state-info, $state-darken);
}


body {
    font-family: 'akkurat' !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    background-color: #F5F5F5 !important;
}

.border-dashed {
    border: 5px dashed #cfcfcf;
}

a {
    text-decoration: none;
}

.h-base {
  user-select:none;
  font-weight: 700;
  font-style: normal;
}

h2 {
  @extend .h-base;
  font-family: 'akkurat' !important;
  font-size: 2.25rem;
}

h3 {
  @extend .h-base;
  font-family: 'akkurat' !important;
  font-size: 1.75rem;
}

h4 {
  @extend .h-base;
  font-family: 'akkurat' !important;
  font-size: 1.25rem;
}

label {
  @extend .h-base;
}

h5 {
  @extend .h-base;
  font-family: 'akkurat' !important;
  font-size: 1rem;
  margin-bottom: 0 !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
}

.pointer {
    cursor: pointer;
}

.badge-status {
    border: 1px solid;
    min-width: 100px;
    font-size: 0.875rem;
    color: $black;
}

.badge-status-yellow {
    @extend .badge-status;
    background-color: $state-warning;
    border-color: darken($state-warning, $state-darken);
}

.badge-status-green {
    @extend .badge-status;
    background-color: $state-success;
    border-color: darken($state-success, $state-darken);
}

.badge-status-red {
    @extend .badge-status;
    background-color: $state-error;
    border-color: darken($state-error, $state-darken);
}

.badge-status-gray {
    @extend .badge-status;
    background-color: $light-gray;
    border-color: darken($light-gray, 10%);
}

.badge-status-blue {
  @extend .badge-status;
  background-color: $state-info;
  border-color: darken($state-info, $state-darken);
}

.message-alert {
  background-color: $state-info;
  border-color: darken($state-info, $state-darken);
  border: 1px solid;
    color: $black;
}

.btn-round {
    border-radius: 50% !important; // Makes the button fully round
    padding: 0 !important; // Reset padding to center content properly

    width: 50px; // Set fixed width
    height: 50px; // Set fixed height to make it circular

    display: inline-flex !important; // Flexbox to center content
    justify-content: center;
    align-items: center;
  }

.btn-primary {
    color: $white !important;
    &:hover {
        background-color: $mf-blue-dark !important;
    }
}

.btn-secondary {
  color: $mf-blue !important;
  &:hover {
      background-color: $mf-blue !important;
      color: $white !important;
  }
}

.btn-tertiary {
  background-color: $state-info !important;
  color: $mf-blue !important;
}

.btn-inverted {
    color: $mf-blue !important;
    &:hover {
        background-color: $mf-blue !important;
        color: $white !important;
    }
}

.btn-no-style {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  color: $mf-blue !important;
}

.customer-dialogue {
    background-color: $customer-dialogue-color;
    border: 1px solid darken($customer-dialogue-color, $state-darken);
}

.customer-service-dialogue {
    background-color: $customer-service-dialogue-color;
    border: 1px solid darken($customer-service-dialogue-color, $state-darken);
}

.box-shadow {
    box-shadow: 0px 10px 30px 0px #0000000F;
}

.form-control, .dropdown-menu {
  border-radius: $input-border-radius;
}



/*
 *** For future reference ***
X-Small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { ... }

*/
