

$mf-blue: #144a92;
$mf-blue-dark: #0F3970;
$info-blue: #138496;
$light-blue: #E7EDF4;
$green: #28a745;
$red: #FC4949;
$yellow: #F9B54A;
$black: #000;
$white: #fff;
$gray: #757575;
$beige: #F1E9DF;
$light-gray: #EBEBEB;

//ngx-toastr color-theme
$state-success: rgba(238, 248, 241, 0.8);
$state-info: rgba(232, 237, 245, 0.8);
$state-warning: rgba(254, 248, 236, 0.8);
$state-error: rgba(251, 233, 233, 0.8);

$state-darken: 20%;

$customer-dialogue-color: #F4F8FC;
$customer-service-dialogue-color: $beige;

$input-border-radius: 5px;

$theme-colors: (
    "primary": $mf-blue,
    "info" : $info-blue,
    "secondary": $light-blue,
    "success": $green,
    "warning": $yellow, // not used
    "danger": $red,
    "black": $black,
    "inverted": $white,
    "gray": $gray,
    "beige": $beige,
    "gray-light": $light-gray,
    "success-light": $state-success
);

@font-face {
    font-family: 'akkurat';
    src: url('/assets/fonts/lineto-akkurat-pro-regular.woff2') format('woff2');
    font-style: normal;
}

// bootstrap button overrides
$btn-border-radius: 25px;
$btn-border-radius-sm: 100px;
$btn-padding-x: 20px;
$btn-border-radius: 25px;
$btn-font-family: 'akkurat';
$btn-font-size-sm: 1rem;
$btn-font-size: 1.125rem;

// bootstrap accordion overrides
$accordion-button-active-bg: $white;
$accordion-button-focus-box-shadow: none;
$accordion-icon-width: 0.825rem;
$custom-accordion-icon-color: $mf-blue-dark;
$accordion-border-radius: 10px;

// bootstrap dropdown-item override
$dropdown-link-active-color: $black;
$dropdown-link-active-bg: $beige;

// fontawesome plus-icon
$accordion-button-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{$custom-accordion-icon-color}' d='M448 224H288V64c0-17.67-14.33-32-32-32s-32 14.33-32 32v160H64c-17.67 0-32 14.33-32 32s14.33 32 32 32h160v160c0 17.67 14.33 32 32 32s32-14.33 32-32V288h160c17.67 0 32-14.33 32-32s-14.33-32-32-32z'/%3E%3C/svg%3E");
// fontawesome minus-icon
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{$custom-accordion-icon-color}' d='M416 208H96c-17.67 0-32 14.33-32 32s14.33 32 32 32h320c17.67 0 32-14.33 32-32s-14.33-32-32-32z'/%3E%3C/svg%3E");
$accordion-icon-transition: transform .25s ease-in-out;

// bootstrap border
$border-radius: 20px;
$border-radius-sm: 10px;
